import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../../../entities/user.model';

import { Conversation } from '../../../entities/conversation.model';
import { SharedService } from '../../../shared/services/shared.service';
import { GenericHttpService } from 'src/app/shared/services/Generic HTTP/generic-http.service';
import { Message } from 'src/app/entities/message.model';

@Injectable({
  providedIn: 'root',
})
export class MessagingService {
  constructor(
    private _http: GenericHttpService,
    private sharedService: SharedService
  ) {
    this.sharedService.currentUser.subscribe(
      (user) => (this.currentUser = user)
    );
  }

  private currentUser: User;

  getConversation(recipient_id: number): Observable<Conversation> {
    return this._http.get(`/conversations/${recipient_id}`);
  }

  getMedia(media_id: number): Observable<Array<string>> {
    return this._http.get(`/conversations/get-media/${media_id}`);
  }

  sendMessage(msg: Message, conversation_id: number): Observable<Message> {
    msg.user_id = this.currentUser.id;
    return this._http.post(
      '/conversations/' + conversation_id + '/messages',
      msg
    );
  }

  getOtherUserByExternalId(external_id: string): Observable<User> {
    return this._http.get(`/users/external/${external_id}`);
  }
}
