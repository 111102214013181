import { Injectable } from '@angular/core';
import { GenericHttpService } from '../Generic HTTP/generic-http.service';
import { BehaviorSubject } from 'rxjs';
import { WebsocketService } from '../../../components/messaging/service/websocket.service';
import { SharedService } from '../shared.service';
import { environment } from '../../../../environments/environment';
import { MonitoringService } from '../monitoring/monitoring.service';
import { Router } from '@angular/router';
import { AlertService } from '../../components/alert/service/alert.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private unreadMessagesCountSource: BehaviorSubject<number> =
    new BehaviorSubject<number>(null);
  public unreadMessagesCount = this.unreadMessagesCountSource.asObservable();
  private userChannel: any;
  private currentUser: any;

  constructor(
    private http: GenericHttpService,
    private webSocketService: WebsocketService,
    private sharedService: SharedService,
    private router: Router,
    private alertService: AlertService,
    private translationService: TranslateService
  ) {
    this.sharedService.currentUser.subscribe((user) => {
      this.currentUser = user;
      if (user) {
        this.listenForPossibleNotifications();
      } else {
        this.log('No current user');
      }
    });
  }

  getUnreadMessageCount() {
    // Deactivating this, as it is very flakey, and hard to keep the count accurate
    // this.http.get('/conversations/unread-messages-count').subscribe(
    //   (response) => {
    //     this.log('Unread message count response:', response);
    //     this.unreadMessagesCountSource.next(response.count);
    //   },
    //   (error) => {
    //     MonitoringService.captureException(error);
    //     console.error('Failed to fetch unread message count:', error);
    //   }
    // );
  }

  ensureBrowserApiPermission() {
    if (environment.areBrowserNotificationsEnabled) {
      if ('Notification' in window && Notification.permission !== 'granted') {
        Notification.requestPermission().then(
          (permission) => {
            this.log('Notification permission status:', permission);
          },
          (error) => {
            MonitoringService.captureException(error);
            console.error('Failed to request notification permission:', error);
          }
        );
      } else {
        // this is too noisy
        // console.error(
        //   'This browser does not support desktop notification or permission already granted'
        // );
      }
    }
  }

  hasPermission(): boolean {
    return Notification.permission === 'granted';
  }

  private showNotification(titleKey: string, bodyKey: string, route: string) {
    try {
      if (environment.areBrowserNotificationsEnabled && this.hasPermission()) {
        const notification = new Notification(
          this.translationService.instant(titleKey),
          {
            body: this.translationService.instant(bodyKey),
            icon: 'favicon.ico',
          }
        );
        notification.onclick = (event) => {
          event.preventDefault(); // Prevent the browser from focusing the Notification's tab
          window.focus();
          this.router.navigate([route]); // Navigate to the specific route
        };
      }
    } catch (error) {
      MonitoringService.captureException(error);
    }
  }

  private showAlert(
    titleKey: string,
    messageKey: string,
    viewCtaKey: string,
    route: string
  ) {
    try {
      this.alertService.info(messageKey, titleKey, true, [
        {
          text: viewCtaKey,
          action: () => {
            this.router.navigate([route]); // Navigate to the specific route
          },
        },
      ]);
    } catch (error) {
      MonitoringService.captureException(error);
    }
  }

  private handleNotification(
    titleKey: string,
    messageKey: string,
    viewCtaKey: string,
    route: string
  ) {
    try {
      this.log('Handling notification:', {
        titleKey,
        messageKey,
        viewCtaKey,
        route,
      });
      if (this.isVisible()) {
        this.showAlert(titleKey, messageKey, viewCtaKey, route);
      } else {
        this.showNotification(titleKey, messageKey, route);
      }
    } catch (error) {
      MonitoringService.captureException(error);
      console.error('Failed to handle notification:', error);
    }
  }

  private listenForPossibleNotifications() {
    try {
      this.userChannel = this.webSocketService.getUserChannel(this.currentUser);
      this.userChannel.received().subscribe(
        (data) => {
          this.log('Notification received:', data);
          try {
            if (data.signal === 'new_video_wait') {
              // We currently have a persistent waiting room announcement. Want to make sure we can make this work as good as that
              // this.handleNotification(
              //   'notifications.new_waiting_room_client.title',
              //   'notifications.new_waiting_room_client.message',
              //   'notifications.new_waiting_room_client.cta',
              //   '/home/video-waiting-room'
              // );
            } else if (data.signal === 'new_message') {
              const externalSenderId = data.message_sender_external_id;
              // Don't bother showing notifications for convos they are looking at, or if
              // they are on the messaging page, which should refresh automatically
              if (
                (this.router.url !== '/home/messaging/' + externalSenderId &&
                  this.router.url !== '/home/messaging') ||
                !this.isVisible()
              ) {
                this.handleNotification(
                  'notifications.new_message.title',
                  'notifications.new_message.message',
                  'notifications.new_message.cta',
                  '/home/messaging'
                );
              }
            }
          } catch (error) {
            MonitoringService.captureException(error);
          }
        },
        (error) => {
          MonitoringService.captureException(error);
        }
      );
      this.userChannel.rejected().subscribe((err) => {
        MonitoringService.captureException(err);
      });
    } catch (error) {
      MonitoringService.captureException(error);
    }
  }

  private isVisible() {
    return document.visibilityState === 'visible';
  }

  private log(message: string, ...optionalParams: any[]) {
    if (environment.isNotificationLoggingEnabled) {
      console.log('DEBUG NOTIFICATION', message, ...optionalParams);
    }
  }
}
