import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Channel } from 'angular2-actioncable';
import { merge } from 'rxjs';
import { ProfileService } from 'src/app/components/profile/service/profile.service';
import { OfficeService } from 'src/app/office/shared/service/office.service';
import { Animation } from 'src/app/shared/animations/fade-animation';
import { InviteClientsService } from 'src/app/shared/components/invite-clients/service/invite-clients.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SupportService } from 'src/app/shared/services/support.service';
import { VideoService } from 'src/app/shared/video/video.service';
import { environment } from 'src/environments/environment';
import { ICAuthService } from '../../auth/service/i-c-auth.service';
import { WebsocketService } from '../../components/messaging/service/websocket.service';
import { User } from '../../entities/user.model';
import { AmplitudeAnalyticsService } from '../../shared/services/analytics/amplitude-analytics.service';
import { FeaturesService } from '../../shared/services/features/features.service';
import { InvoiceAttentionBanner } from '../../shared/services/invoiceAttentionBanner';
import { MonitoringService } from '../../shared/services/monitoring/monitoring.service';
import { Profile } from './../../entities/profile.model';
import { GenericHttpService } from './../../shared/services/Generic HTTP/generic-http.service';
import { TranslateModule } from '@ngx-translate/core';
import { UpcomingCommunityEventsComponent } from './upcoming-community-events/upcoming-community-events.component';
import { PhotoUploaderComponent } from '../../shared/photo-uploader/photo-uploader.component';
import { ProfilePictureComponent } from '../../shared/components/profile-picture/profile-picture.component';
import { NgClass, NgIf } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { createEditProfileLink } from '../../shared/helpers/navigation_helper';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  animations: [Animation.fadeAnimation],
  standalone: true,
  imports: [
    NgIf,
    ProfilePictureComponent,
    PhotoUploaderComponent,
    NgClass,
    RouterLink,
    UpcomingCommunityEventsComponent,
    TranslateModule,
    MatIcon,
  ],
})
export class SidenavComponent implements OnInit {
  channel: Channel;
  userChannel: Channel;

  profile: Profile;

  @ViewChild('waitingRoomLink', { read: ElementRef, static: false })
  waitingRoomLink: ElementRef;

  clients: any[];
  hasInvoiceAttentionBanner: boolean;

  findATherapistDestination = '/find-a-therapist';

  constructor(
    private authService: ICAuthService,
    private profileService: ProfileService,
    private sharedService: SharedService,
    private featuresService: FeaturesService,
    private notificationService: NotificationsService,
    private activatedRoute: ActivatedRoute,
    private inviteClientsService: InviteClientsService,
    private officeService: OfficeService,
    private videoService: VideoService,
    private webSocketService: WebsocketService,
    private http: GenericHttpService,
    private supportService: SupportService,
    private router: Router,
    private analytics: AmplitudeAnalyticsService,
    private featureService: FeaturesService
  ) {}

  public env = environment;
  currentUser: User;

  @Input()
  public isSettings: boolean;

  unreadMessagesCount: number;
  showUploader: boolean = false;

  lang: string;

  public isMobileSettingsDropdownOpen: boolean = false;
  public currentSettingsSection: string;
  public myOfficeAvailable: boolean = false;
  public myOfficeNotSupported: boolean = false;
  public isDevMenuEnabled: boolean = false;

  ngOnInit() {
    this.sharedService.currentUser.subscribe((user) => {
      if (user) {
        this.profile = user.profile;
        this.getClients();
        this.currentUser = user;
        this.lang = user.locale;
        this.myOfficeAvailable = this.env.stripe_available_countries.includes(
          this.currentUser.profile.country_code
        );
        this.myOfficeNotSupported =
          this.currentUser.profile.country_code && !this.myOfficeAvailable;

        this.userChannel = this.webSocketService.getUserChannel(user);
        this.userChannel.received().subscribe((data) => {
          if (
            data.signal === 'new_video_wait' ||
            data.signal === 'remove_video_wait'
          ) {
            this.getClients();
          }
        });

        this.isDevMenuEnabled = !environment.production;
        this.findATherapistDestination = !user.cannot_book_eap_session_reason
          ? '/eap'
          : '/find-a-therapist';
      }
    });
    this.profileService.profile.subscribe((profile) => {
      if (profile) this.currentUser.profile = profile;
    });
    this.notificationService.unreadMessagesCount.subscribe((count) => {
      this.unreadMessagesCount = count;
    });
    if (this.isSettings) {
      this.activatedRoute.url.subscribe((params) => {
        this.currentSettingsSection = params[params.length - 1].path;
      });
    }
    this.sharedService.currentInvoiceAttentionBanner().subscribe((banner) => {
      this.hasInvoiceAttentionBanner = banner != InvoiceAttentionBanner.Hidden;
    });
  }

  logout() {
    this.authService.signOut();
  }

  showProfile() {
    if (this.currentUser.type == 'Therapist') {
      window.open(this.currentUser.profile.link, '_blank');
    }
  }

  showInviteClientsModal() {
    this.inviteClientsService.openModal();
  }

  shouldShowUpcomingEvents() {
    return this.router.url === '/home';
  }

  requestSupport() {
    this.supportService.showReportModal();
  }

  openProfileEditorV2() {
    window.location.href = createEditProfileLink(
      this.currentUser.locale,
      this.currentUser.profile
    );
  }

  createNewInvoice() {
    this.officeService.toggleCreateInvoiceModal(true);
  }

  openCommunity() {
    this.analytics.trackOpenedCommunity({
      source_page: 'dashboard_sidebar',
    });
    window.open('https://community.complicated.life', '_blank');
  }

  call(id) {
    this.channel = this.webSocketService.getVideoChannel(id);
    this.channel.received().subscribe((data) => {
      if (!!this[data['action']]) {
        this[data['action']](data['data']);
      }
    });
    this.channel.connected().subscribe(() => {
      this.channel.perform('call', {});
    });
  }

  getClients() {
    this.http.get('/onlinesessions').subscribe((sessions) => {
      this.clients = sessions;
    });
  }

  testTriggerUnauthorized() {
    merge(
      this.http.get('/_/http/401'),
      this.http.get('/_/http/401')
    ).subscribe();
  }

  testCaptureSentryMessage() {
    console.error('Capturing test message for Sentry');
    MonitoringService.captureMessage('Test message');
  }
}
